.container {
  display: flex;
  justify-content: space-between;
  max-width: 275px;
  background: #ffffff;
  padding: 24px 16px 30px 34px;
}

.text {
  margin-right: 20px;
}

.title {
  font-size: 12px;
  margin-bottom: 5px;
}

.number {
  font-size: 30px;
  font-weight: bold;
}

.icon {
  width: 55px;
  height: 55px;
}