.container {
  background-color: white;
  padding: 35px 35px 50px;
  margin-bottom: 280px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.filters {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.filters:last-child {
  margin-bottom: 0;
}

.filter {
  max-width: 400px;
  width: 100%;
}

.filterSecondary {
  max-width: 255px;
  width: 100%;
  margin-right: 30px;
}

.filterSecondary:last-child {
  margin-right: 40px;
}

.filterDates {
  max-width: 255px;
  width: 100%;
  margin-right: 30px;
}

.filterDates :global(.DateInput) {
  width: 100px;
}

.filterDates :global(.DateInput_input) {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 11px 5px;
}

.filterDates :global(.DateInput_input::placeholder) {
  color: #aaaaaa;
  text-align: center;
}

.filterDates :global(.DateRangePickerInput_arrow_svg) {
  fill: #aaaaaa;
}

.filterDates :global(.DateRangePickerInput__withBorder) {
  border: 1px solid #e8eaec;
}

.button {
  min-width: 160px;
  height: 33px;
  border: none;
  border-radius: 6px;
  background-color: rgba(219, 53, 42, 0.9);
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(219, 53, 42, 1);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button_icon {
  width: 11px;
  height: 11px;
  margin-right: 9px;
}

.oneContainer {
  padding: 30px 0 50px;
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
}

.oneContainer:first-of-type {
  padding-top: 0;
}

.oneContainer:last-of-type {
  border-bottom: none;
}