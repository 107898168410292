.regionInfo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 160px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transform: translateY(calc(-100% - 5px)) translateX(-50%);
  padding: 10px 15px;
}

.regionInfo::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
  transform: translateY(100%) translateX(-50%);
}

.regionalInfo__title {
  font-size: 13px;
  line-height: 1.46;
  margin-bottom: 5px;
}

.regionalInfo__data {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46;
}