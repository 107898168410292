.container {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.filterLabel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

.filterText {
  margin-right: 30px;
}

.filter {
  max-width: 465px;
  width: 100%;
}

.chartContainer {
  display: flex;
}

.chart {
  width: 250px;
  height: 250px;
  margin-right: 50px;
  margin-left: 50px;
}

.chartTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 35px;
}

.tables {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.table {
  border-collapse: collapse;
  width: 50%;
}

.thead {
  font-weight: 600;
  letter-spacing: 0.14px;
  background-color: #f1f1f1;
  border-right: 2px solid white;
}

.thead:last-child {
  border-right: none;
}

.theadTd {
  width: 180px;
  padding: 10px 0 10px 20px;
}

.theadTdNumber{
  width: 50px;
}

.theadTdRegion {
  text-align: left;
  width: 400px;
}

.tr {
  border-bottom: 1px solid #ececec;
}

.tr:last-child {
  border-bottom: none;
}

.td {
  padding: 15px 0 15px 20px;
}


.graphContainer {
  display: flex;
}

.graphLegendList {
  list-style: none;
  max-width: 280px;
  margin-top: 30px;
  margin-right: 20px;
}

.graphLegendItem {
  margin-bottom: 15px;
}

.graphNumber {
  font-weight: 600;
}