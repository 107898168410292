.container {
  background-color: white;
  padding: 35px 20px 35px 35px;
  margin-bottom: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.indicators {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 80px;
  margin-bottom: 35px;
}

.indicators::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ececec;
}

.indicatorsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 20px;
}

.indicatorsItem_active {
  border-bottom: 3px solid rgba(236, 65, 44, 0.8);
  z-index: 2;
}

.text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  margin-bottom: 5px;
}

.number {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.43;
}

.map {
  width: 750px;
  height: 450px;
  background-image: url('../../resources/image/map.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 50px;
}

.mapContainer {
  display: flex;
}

.chart {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 40px;
  margin-left: 25px;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 0 40px 35px;
}

.legend {
  list-style: none;
}

.legend__item {
  margin-bottom: 20px;
}

.legend__item:last-child {
  margin-bottom: 0;
}

.infoCards {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 25px;
}

.infoCards_item {
  margin-right: 25px;
}

.infoCards_item:last-child {
  margin-right: 0;
}