.select {
    min-width: 250px;
}

.select .select__control {
    border: solid 1px #e8eaec;
    border-radius: 0;
    height: 35px;
    min-height: 35px;
}

.select .select__control.select__control--is-focused {
    box-shadow: 0 0 0 1px rgba(236, 65, 44, .8);
}

.select .select__control:hover {
    border-color: rgba(236, 65, 44, .8);
}

.select .select__option.select__option--is-focused {
    background-color: rgba(236, 65, 44, .1);
}

.select .select__option.select__option--is-selected {
    background-color: rgba(236, 65, 44, 1);
}

.select .select__value-container {
    height: 33px;
}

.select .select__indicators {
    height: 33px;
}

.select .select__placeholder {
    color: #aaaaaa;
    border-radius: 0;
}

.select__indicator-separator {
    display: none;
}
