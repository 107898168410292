.container {
  background-color: white;
  max-width: calc(50% - 15px);
  padding: 35px 35px 50px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.containerWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.indicators {
  display: flex;
  justify-content: space-between;
  padding-left: 65px;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

.chart {
  max-width: 250px;
  max-height: 250px;
  align-self: center;
  margin-bottom: 40px;
}

.legend {
  list-style: none;
  flex: 1;
}

.legend__item {
  margin-bottom: 20px;
}

.legend__item:last-child {
  margin-bottom: 0;
}