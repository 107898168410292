.container {
  background-color: white;
  padding: 35px 25px 100px 45px;
  margin-bottom: 25px;
}

.menu {
  display: flex;
  position: relative;
  margin-bottom: 40px;
}

.menu::after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #ececec;
}

.button {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  border-bottom: 3px solid transparent;
  padding: 12px 15px;
  margin-right: 70px;
}

.button:last-child {
  margin-right: 0;
}

.button_active {
  border-bottom: 3px solid rgba(236, 65, 44, 0.8);
  z-index: 2;
}

.filters {
  display: flex;
  margin-bottom: 20px;
}

.filter {
  max-width: 400px;
  width: 100%;
}

.filter_theme {
  max-width: 250px;
  margin-left: 30px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.thead {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.tbody {
  width: 100%;
  overflow: auto;
}

.theadTd {
  width: 205px;
  padding: 20px 0 20px 20px;
  text-align: center;
}

.theadTdExecutor {
  text-align: left;
  width: 400px;
}

.td {
  width: 205px;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.tdNumber {
  width: 50px;
  padding-left: 20px;
}

.tdExecutor {
  width: 400px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
}

.tr {
  border-bottom: 1px solid #ececec;
}

.tr:first-child .td {
  padding-top: 35px;
}

.icon {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}