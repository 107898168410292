.container {
  margin-top: 25px;
}

.wrapper {
  max-width: 1220px;
  padding: 0 25px;
  margin: 0 auto;
}

.wrapperHeader {
  max-width: 1220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin: 0 auto;
}

.header {
  background: #fff;
  margin-bottom: 25px;
}

.headerText {
  font-weight: bold;
  padding: 20px 0;
}

.title {
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  margin-bottom: 10px;
}

.filterDates :global(.DateInput) {
  width: 135px;
}

.filterDates :global(.DateInput_input) {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 11px 5px;
}

.filterDates :global(.DateInput_input::placeholder) {
  color: #aaa;
  text-align: center;
}

.filterDates :global(.DateRangePickerInput_arrow_svg) {
  fill: #aaa;
}

.filterDates :global(.DateRangePickerInput__withBorder) {
  border: 1px solid #e8eaec;
}

.headerSelectsContainer {
  display: flex;
}

.headerSelectType {
  margin-right: 20px;
}

.buttonWrapper {
  max-width: 1220px;
  padding: 0 25px;
  margin: 0 auto 25px;
}

.button {
  display: flex;
  align-items: center;
  position: relative;
  color: #ec412c;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding-left: 20px;
}

.button::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 13px;
  left: 0;
  display: inline-block;
  transform: rotate(-90deg);
  background-size: contain;
  background: url(../../resources/svg/arrow-red.svg) no-repeat;
  margin-right: 5px;
}

.icon {
  height: 8px;
  width: 13.5px;
  transform: rotate(-90deg);
}