.container {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.thead {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.theadTd {
  width: 190px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.theadTdRegion {
  text-align: left;
  width: 300px;
}

.td {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.tdNumber {
  width: 50px;
}

.tdRegion {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.tr {
  border-bottom: 1px solid #ececec;
}

.tr:first-child .td {
  padding-top: 35px;
}