.container {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.filters {
  display: flex;
  margin-bottom: 40px;
}

.filters__district {
  
}

.filters__region {
  max-width: 290px;
  width: 100%;
  margin-right: 30px;
}

.filters__theme {
  margin-right: 30px;
}

.filters__executors {
  
}

.tables {
  display: flex;
  justify-content: space-between;
}

.table {
  border-collapse: collapse;
  width: calc(50% - 25px);
}

.thead {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.theadTd {
  width: 265px;
  padding: 20px 0 20px 20px;
}

.theadTdAmount {
  text-align: center;
}

.td {
  font-weight: 600;
  padding: 25px 0 25px 20px;
}

.tdNumber {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}

.tdAmount {
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}

.tr {
  border-bottom: 1px solid #ececec;
}

.tr:first-child .td {
  padding-top: 35px;
}