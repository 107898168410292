@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #f3f3f4;
  font-family: 'Open Sans', sans-serif;
  color: #676a6c;
  height: 100%;
  font-size: 14px;
  line-height: 1.43;
}

button {
  font-family: 'Open Sans', sans-serif;
  color: #676a6c;
  font-size: 14px;
  line-height: 1.43;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.apexcharts-datalabels {
  display: none;
}

.apexcharts-data-labels {
  display: none;
}
.App_container__16xz_ {

}
.analytics_container__2D7J0 {
  margin-top: 25px;
}

.analytics_wrapper__3CvqI {
  max-width: 1220px;
  padding: 0 25px;
  margin: 0 auto;
}

.analytics_wrapperHeader__2gMsK {
  max-width: 1220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin: 0 auto;
}

.analytics_header__gqG0e {
  background: #fff;
  margin-bottom: 25px;
}

.analytics_headerText__F3TUG {
  font-weight: bold;
  padding: 20px 0;
}

.analytics_title__1-Bsm {
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  margin-bottom: 10px;
}

.analytics_filterDates__3d3_v .DateInput {
  width: 135px;
}

.analytics_filterDates__3d3_v .DateInput_input {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 11px 5px;
}

.analytics_filterDates__3d3_v .DateInput_input::placeholder {
  color: #aaa;
  text-align: center;
}

.analytics_filterDates__3d3_v .DateRangePickerInput_arrow_svg {
  fill: #aaa;
}

.analytics_filterDates__3d3_v .DateRangePickerInput__withBorder {
  border: 1px solid #e8eaec;
}

.analytics_headerSelectsContainer__3WYap {
  display: flex;
}

.analytics_headerSelectType__2cfbm {
  margin-right: 20px;
}

.analytics_buttonWrapper__2mwXz {
  max-width: 1220px;
  padding: 0 25px;
  margin: 0 auto 25px;
}

.analytics_button__3zcQI {
  display: flex;
  align-items: center;
  position: relative;
  color: #ec412c;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding-left: 20px;
}

.analytics_button__3zcQI::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 13px;
  left: 0;
  display: inline-block;
  transform: rotate(-90deg);
  background-size: contain;
  background: url(/static/media/arrow-red.ccb69fa8.svg) no-repeat;
  margin-right: 5px;
}

.analytics_icon__2nPVE {
  height: 8px;
  width: 13.5px;
  transform: rotate(-90deg);
}
.generalIndicators_container__3Mjrs {
  background-color: white;
  padding: 35px 20px 35px 35px;
  margin-bottom: 25px;
}

.generalIndicators_title__525lC {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.generalIndicators_indicators__-x4OL {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 80px;
  margin-bottom: 35px;
}

.generalIndicators_indicators__-x4OL::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ececec;
}

.generalIndicators_indicatorsItem__32l6- {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 20px;
}

.generalIndicators_indicatorsItem_active__1Bxhz {
  border-bottom: 3px solid rgba(236, 65, 44, 0.8);
  z-index: 2;
}

.generalIndicators_text__AjMoM {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  margin-bottom: 5px;
}

.generalIndicators_number__2KBzi {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.43;
}

.generalIndicators_map__30s-7 {
  width: 750px;
  height: 450px;
  background-image: url(/static/media/map.a64f12f3.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 50px;
}

.generalIndicators_mapContainer__3zfbX {
  display: flex;
}

.generalIndicators_chart__3KWAw {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 40px;
  margin-left: 25px;
}

.generalIndicators_chartContainer__1Gk1k {
  display: flex;
  flex-direction: column;
  margin: 40px 0 40px 35px;
}

.generalIndicators_legend__EKY5I {
  list-style: none;
}

.generalIndicators_legend__item__1X4nt {
  margin-bottom: 20px;
}

.generalIndicators_legend__item__1X4nt:last-child {
  margin-bottom: 0;
}

.generalIndicators_infoCards__3e32o {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 25px;
}

.generalIndicators_infoCards_item__530_n {
  margin-right: 25px;
}

.generalIndicators_infoCards_item__530_n:last-child {
  margin-right: 0;
}
.legendItem_container__1iBj2 {
  display: flex;
  justify-content: space-between;
}

.legendItem_circle__3DZc8 {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-right: 8px;
  border-radius: 100%;
}

.legendItem_title__1AdiR {
  letter-spacing: 0.14px;
  margin-right: auto;
}

.legendItem_titleWrapper__TnUwm {
  display: flex;
  margin-right: 20px;
}

.legendItem_data__VBiYa {
  min-width: 90px;
  font-weight: bold;
}
.infoCard_container__1msKU {
  display: flex;
  justify-content: space-between;
  max-width: 275px;
  background: #ffffff;
  padding: 24px 16px 30px 34px;
}

.infoCard_text__3DUXS {
  margin-right: 20px;
}

.infoCard_title__NQO7T {
  font-size: 12px;
  margin-bottom: 5px;
}

.infoCard_number__3bQcK {
  font-size: 30px;
  font-weight: bold;
}

.infoCard_icon__3Br9N {
  width: 55px;
  height: 55px;
}
.css-loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.loading-container {
  position: relative;
}

.loading-container .css-loader {
  display: block;
}

.loading-container > *:not(.css-loader) {
  opacity: 0.4;
  pointer-events: none;
}

.dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid;
  border-color: #ec412c transparent #151515 transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.map_regionInfo__194Hk {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 160px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transform: translateY(calc(-100% - 5px)) translateX(-50%);
  padding: 10px 15px;
}

.map_regionInfo__194Hk::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
  transform: translateY(100%) translateX(-50%);
}

.map_regionalInfo__title__30xC9 {
  font-size: 13px;
  line-height: 1.46;
  margin-bottom: 5px;
}

.map_regionalInfo__data__1FhDd {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46;
}
.issueDeadlines_container__2yI-Z {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.issueDeadlines_title__6fpkw {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.issueDeadlines_filterLabel__1V0e_ {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

.issueDeadlines_filterText__2D-1A {
  margin-right: 30px;
}

.issueDeadlines_filter__ZnxTN {
  max-width: 465px;
  width: 100%;
}

.issueDeadlines_chartContainer__3gjxa {
  display: flex;
}

.issueDeadlines_chart__2nYqw {
  width: 250px;
  height: 250px;
  margin-right: 50px;
  margin-left: 50px;
}

.issueDeadlines_chartTitle__3cl0k {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 35px;
}

.issueDeadlines_tables__1QFM7 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.issueDeadlines_table__3Owve {
  border-collapse: collapse;
  width: 50%;
}

.issueDeadlines_thead__1XpjA {
  font-weight: 600;
  letter-spacing: 0.14px;
  background-color: #f1f1f1;
  border-right: 2px solid white;
}

.issueDeadlines_thead__1XpjA:last-child {
  border-right: none;
}

.issueDeadlines_theadTd__2YK0w {
  width: 180px;
  padding: 10px 0 10px 20px;
}

.issueDeadlines_theadTdNumber__36ofY{
  width: 50px;
}

.issueDeadlines_theadTdRegion__3NyB7 {
  text-align: left;
  width: 400px;
}

.issueDeadlines_tr__1skDi {
  border-bottom: 1px solid #ececec;
}

.issueDeadlines_tr__1skDi:last-child {
  border-bottom: none;
}

.issueDeadlines_td__1fT8G {
  padding: 15px 0 15px 20px;
}


.issueDeadlines_graphContainer__2OGHb {
  display: flex;
}

.issueDeadlines_graphLegendList__1IseA {
  list-style: none;
  max-width: 280px;
  margin-top: 30px;
  margin-right: 20px;
}

.issueDeadlines_graphLegendItem__2Wh-g {
  margin-bottom: 15px;
}

.issueDeadlines_graphNumber__KmZnZ {
  font-weight: 600;
}
.select {
    min-width: 250px;
}

.select .select__control {
    border: solid 1px #e8eaec;
    border-radius: 0;
    height: 35px;
    min-height: 35px;
}

.select .select__control.select__control--is-focused {
    box-shadow: 0 0 0 1px rgba(236, 65, 44, .8);
}

.select .select__control:hover {
    border-color: rgba(236, 65, 44, .8);
}

.select .select__option.select__option--is-focused {
    background-color: rgba(236, 65, 44, .1);
}

.select .select__option.select__option--is-selected {
    background-color: rgba(236, 65, 44, 1);
}

.select .select__value-container {
    height: 33px;
}

.select .select__indicators {
    height: 33px;
}

.select .select__placeholder {
    color: #aaaaaa;
    border-radius: 0;
}

.select__indicator-separator {
    display: none;
}

.issuesAmount_container__1_ibn {
  background-color: white;
  max-width: calc(50% - 15px);
  padding: 35px 35px 50px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.issuesAmount_containerWrapper__rgTnu {
  display: flex;
  justify-content: space-between;
}

.issuesAmount_title__37ucH {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.issuesAmount_indicators__2jcyE {
  display: flex;
  justify-content: space-between;
  padding-left: 65px;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

.issuesAmount_chart__1lDn2 {
  max-width: 250px;
  max-height: 250px;
  align-self: center;
  margin-bottom: 40px;
}

.issuesAmount_legend__36zL2 {
  list-style: none;
  flex: 1 1;
}

.issuesAmount_legend__item__1qDh3 {
  margin-bottom: 20px;
}

.issuesAmount_legend__item__1qDh3:last-child {
  margin-bottom: 0;
}
.issuesByStatus_container__3c98u {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.issuesByStatus_title__2PGBy {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.issuesByStatus_filters__37Hcj {
  display: flex;
  margin-bottom: 40px;
}

.issuesByStatus_filters__district__38QnK {
  
}

.issuesByStatus_filters__region__1tDlm {
  max-width: 290px;
  width: 100%;
  margin-right: 30px;
}

.issuesByStatus_filters__theme__19JZg {
  margin-right: 30px;
}

.issuesByStatus_filters__executors__1LkKa {
  
}

.issuesByStatus_tables__3fw-F {
  display: flex;
  justify-content: space-between;
}

.issuesByStatus_table__3SvLN {
  border-collapse: collapse;
  width: calc(50% - 25px);
}

.issuesByStatus_thead__2yVzK {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.issuesByStatus_theadTd__3nAnu {
  width: 265px;
  padding: 20px 0 20px 20px;
}

.issuesByStatus_theadTdAmount__2hDXm {
  text-align: center;
}

.issuesByStatus_td__3AHzo {
  font-weight: 600;
  padding: 25px 0 25px 20px;
}

.issuesByStatus_tdNumber__1kvcK {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}

.issuesByStatus_tdAmount__yJuKU {
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}

.issuesByStatus_tr__1jQff {
  border-bottom: 1px solid #ececec;
}

.issuesByStatus_tr__1jQff:first-child .issuesByStatus_td__3AHzo {
  padding-top: 35px;
}
.whoIsApplicant_container__7pf_v {
  background-color: white;
  padding: 35px 100px 35px 35px;
  margin-bottom: 25px;
}

.whoIsApplicant_title__1evwE {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.whoIsApplicant_indicators__3g1wk {
  display: flex;
  justify-content: space-between;
  padding-left: 65px;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

.whoIsApplicant_chart__3ZLvz {
  max-width: 250px;
  max-height: 250px;
  margin-right: 100px;
  margin-left: 50px;
}

.whoIsApplicant_chartContainer__2gviw {
  display: flex;
  align-items: center;
}

.whoIsApplicant_legend__1K1tk {
  list-style: none;
  flex: 1 1;
}

.whoIsApplicant_legend__item__3z2ee {
  margin-bottom: 20px;
}

.whoIsApplicant_legend__item__3z2ee:last-child {
  margin-bottom: 0;
}
.mostIssuesRegions_container__1np4i {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.mostIssuesRegions_title__wdXia {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.mostIssuesRegions_table__1pnHf {
  border-collapse: collapse;
  width: 100%;
}

.mostIssuesRegions_thead__1jQ6c {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.mostIssuesRegions_theadTd__lItnr {
  width: 190px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.mostIssuesRegions_theadTdRegion__2CtEj {
  text-align: left;
  width: 300px;
}

.mostIssuesRegions_td__2plcs {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.mostIssuesRegions_tdNumber__1b_kW {
  width: 50px;
}

.mostIssuesRegions_tdRegion__218vU {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.mostIssuesRegions_tr__L8uAG {
  border-bottom: 1px solid #ececec;
}

.mostIssuesRegions_tr__L8uAG:first-child .mostIssuesRegions_td__2plcs {
  padding-top: 35px;
}
.popularSpheres_container__2XwQ6 {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.popularSpheres_title__1p-pa {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 20px;
}

.popularSpheres_table__oygOt {
  border-collapse: collapse;
  width: 100%;
}

.popularSpheres_thead__pi15S {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.popularSpheres_theadTd__2TFCr {
  width: 205px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.popularSpheres_theadTdSphere__3_SVn {
  text-align: left;
  width: 400px;
}

.popularSpheres_td__3GZOK {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.popularSpheres_tdNumber__2jbCd {
  width: 50px;
}

.popularSpheres_tdSphere__iA0Ly {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.popularSpheres_tr__c-bfG {
  border-bottom: 1px solid #ececec;
}

.popularSpheres_tr__c-bfG:first-child .popularSpheres_td__3GZOK {
  padding-top: 35px;
}
.popularThemes_container__mN2VU {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.popularThemes_title__x3gaJ {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.popularThemes_filters__3G8W3 {
  display: flex;
  margin-bottom: 20px;
}

.popularThemes_filter__3lVkj {
  max-width: 400px;
  width: 100%;
}

.popularThemes_table__2RzWm {
  border-collapse: collapse;
  width: 100%;
}

.popularThemes_thead__Haj0f {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.popularThemes_theadTd__Ob6MJ {
  width: 205px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.popularThemes_theadTdRegion__2yVEG {
  text-align: left;
  width: 400px;
}

.popularThemes_td__1btqL {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.popularThemes_tdNumber__1WaBo {
  width: 50px;
}

.popularThemes_tdRegion__17Uxk {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.popularThemes_tr__1iYst {
  border-bottom: 1px solid #ececec;
}

.popularThemes_tr__1iYst:first-child .popularThemes_td__1btqL {
  padding-top: 35px;
}
.executorsRating_container__1qNSt {
  background-color: white;
  padding: 35px 25px 100px 45px;
  margin-bottom: 25px;
}

.executorsRating_menu__2ck0G {
  display: flex;
  position: relative;
  margin-bottom: 40px;
}

.executorsRating_menu__2ck0G::after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #ececec;
}

.executorsRating_button__2w_ET {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  border-bottom: 3px solid transparent;
  padding: 12px 15px;
  margin-right: 70px;
}

.executorsRating_button__2w_ET:last-child {
  margin-right: 0;
}

.executorsRating_button_active__21OEE {
  border-bottom: 3px solid rgba(236, 65, 44, 0.8);
  z-index: 2;
}

.executorsRating_filters__3_tIQ {
  display: flex;
  margin-bottom: 20px;
}

.executorsRating_filter__2hWrI {
  max-width: 400px;
  width: 100%;
}

.executorsRating_filter_theme__3SDKD {
  max-width: 250px;
  margin-left: 30px;
}

.executorsRating_table__eveaB {
  border-collapse: collapse;
  width: 100%;
}

.executorsRating_thead__1aTPE {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.executorsRating_tbody__2t6T2 {
  width: 100%;
  overflow: auto;
}

.executorsRating_theadTd__Eu_0g {
  width: 205px;
  padding: 20px 0 20px 20px;
  text-align: center;
}

.executorsRating_theadTdExecutor__K04Bv {
  text-align: left;
  width: 400px;
}

.executorsRating_td__8bsSe {
  width: 205px;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.executorsRating_tdNumber__aCmqq {
  width: 50px;
  padding-left: 20px;
}

.executorsRating_tdExecutor__26qLZ {
  width: 400px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
}

.executorsRating_tr__3O9mV {
  border-bottom: 1px solid #ececec;
}

.executorsRating_tr__3O9mV:first-child .executorsRating_td__8bsSe {
  padding-top: 35px;
}

.executorsRating_icon__gg-6G {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}
.reportDownload_container__O6Sp8 {
  background-color: white;
  padding: 35px 35px 50px;
  margin-bottom: 280px;
  display: flex;
  flex-direction: column;
}

.reportDownload_title__3hTsF {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.reportDownload_filters__3PLO0 {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.reportDownload_filters__3PLO0:last-child {
  margin-bottom: 0;
}

.reportDownload_filter___Hzf5 {
  max-width: 400px;
  width: 100%;
}

.reportDownload_filterSecondary__3WUkH {
  max-width: 255px;
  width: 100%;
  margin-right: 30px;
}

.reportDownload_filterSecondary__3WUkH:last-child {
  margin-right: 40px;
}

.reportDownload_filterDates__WTNAy {
  max-width: 255px;
  width: 100%;
  margin-right: 30px;
}

.reportDownload_filterDates__WTNAy .DateInput {
  width: 100px;
}

.reportDownload_filterDates__WTNAy .DateInput_input {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 11px 5px;
}

.reportDownload_filterDates__WTNAy .DateInput_input::placeholder {
  color: #aaaaaa;
  text-align: center;
}

.reportDownload_filterDates__WTNAy .DateRangePickerInput_arrow_svg {
  fill: #aaaaaa;
}

.reportDownload_filterDates__WTNAy .DateRangePickerInput__withBorder {
  border: 1px solid #e8eaec;
}

.reportDownload_button__1W2Kw {
  min-width: 160px;
  height: 33px;
  border: none;
  border-radius: 6px;
  background-color: rgba(219, 53, 42, 0.9);
  color: white;
  cursor: pointer;
}

.reportDownload_button__1W2Kw:hover {
  background-color: rgba(219, 53, 42, 1);
}

.reportDownload_button__1W2Kw:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.reportDownload_button_icon__3lsy3 {
  width: 11px;
  height: 11px;
  margin-right: 9px;
}

.reportDownload_oneContainer__3a1Ib {
  padding: 30px 0 50px;
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
}

.reportDownload_oneContainer__3a1Ib:first-of-type {
  padding-top: 0;
}

.reportDownload_oneContainer__3a1Ib:last-of-type {
  border-bottom: none;
}
