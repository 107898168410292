.container {
  display: flex;
  justify-content: space-between;
}

.circle {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-right: 8px;
  border-radius: 100%;
}

.title {
  letter-spacing: 0.14px;
  margin-right: auto;
}

.titleWrapper {
  display: flex;
  margin-right: 20px;
}

.data {
  min-width: 90px;
  font-weight: bold;
}